<template>
    <div>
        <b-modal
            size="lg"
            title="Freeze Tool"
            ref="modal-1"
            id="modal-1"
            ok-title="Freeze"
            cancel-title="Reset "
            hide-header
            hide-footer
            @ok="FilterManager"
            @cancel="handleReset"
            :visible="isVisible"
            @hide="hideModal"
        >
            <div class="text-right cursor-pointer">
                <img
                    class="pointer"
                    src="../../assets/close_icon.svg"
                    @click="closeModal"
                />
            </div>
            <h5 class="my-2 title text-center">Save filters</h5>
            <div class="manage-form">
                <div class="content">
                    <CustomFloatingLabel
                        :config="{
                            label: 'Filter name',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="filterName"
                            ref="filterName"
                            autocomplete="off"
                            type="text"
                            v-model="filterName"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Filter description',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="filterDescription"
                            ref="filterDescription"
                            autocomplete="off"
                            type="text"
                            v-model="filterDescription"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Category',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="Category"
                            ref="Category"
                            autocomplete="off"
                            type="text"
                            v-model="filterCategory"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <b-button
                        id="freeze-btn-portal"
                        class="applyButton assignedApply mt-2"
                        @click="FilterManager()"
                    >
                        Save
                    </b-button>
                    <b-button
                        id="reset-btn--portal"
                        class="cancelButton"
                        @click="forceReset"
                    >
                        Reset
                    </b-button>
                </div>
            </div>
        </b-modal>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :successToastMessage="successToastMessage"
            @hideToast="hideToast"
        ></ToastMessages>
    </div>
</template>

<script>
import CustomFloatingLabel from '../CustomFloatingLabel';
import { datasetService } from '../../services/dataset.service';
import { mapActions, mapGetters, mapState } from 'vuex';
import ToastMessages from '../ToastMessages.vue';
import _ from 'lodash';

export default {
    name: 'SaveFiltersModal',
    components: {
        CustomFloatingLabel,
        ToastMessages,
    },
    props: {
        isVisible: Boolean,
        projectIds: Number,
        datasetId: Number,
        floatLabelAdd: {
            type: String,
            default: '',
            required: false,
        },
    },
    data() {
        return {
            filterCategory: '',
            filterDescription: '',
            filterName: '',
            UserPortalOptionID: 0,
            ToolID: 6,
            showSuccess: false,
            showFailure: false,
            successToastMessage: 'Filters have been saved successfully!',
        };
    },
    methods: {
        hideToast() {
            this.showSuccess = false;
            this.showFailure = false;
        },
        async CheckExistingFilters(filterName) {  
            let response = null;  
            const res = await datasetService.getSavedWork(parseInt(this.projectIds));  
            for (let item of res.data) {  
                if (item.Name === filterName) {  
                    let userResponse = confirm('Filter name: ' + item.Name + ' already exists. Would you like to overwrite this filter?');  
                    if (userResponse) {  
                        response = item.UserPortalOptionID;  
                        break;  
                    } else {    
                        response = "invalid";  
                        break;  
                    }  
                }  
            }
            return response;  
        },
        async FilterManager() {
            let response = await this.CheckExistingFilters(this.filterName);
            if (response) {
                // User chose not to overwrite, and must now select a new name for the filter
                if (response === "invalid") {
                    this.filterName = '';
                // User has chosen to overwrite the filter
                } else {
                    await datasetService.deleteFilter(response);
                    await this.SaveFilter();
                }
            // Filter name was valid, create new filter
            } else {
                await this.SaveFilter();
            }
        },
        async SaveFilter() {
            let toBeSaved = {};
            toBeSaved.Name = this.filterName;
            toBeSaved.Description = this.filterDescription;
            toBeSaved.Category = this.filterCategory;
            toBeSaved.CreationDate = new Date();
            toBeSaved.UserPortalOptionID = this.UserPortalOptionID;
            toBeSaved.ToolID = this.ToolID;
            toBeSaved.DatasetID = this.datasetId;
            toBeSaved.IsActive = 1;

            let gridFilter = this.getGridFilters({
                dataSetId: this.datasetId,
                projectId: parseInt(this.projectIds),
            });

            let DatasetFilter = [];
            let distinctFields = _.uniqBy(
                gridFilter.selectedFilters,
                'field'
            ).map((itm) => itm.field);

            distinctFields.forEach((col) => {
                let filterVal = gridFilter.selectedFilters
                    .filter((fil) => fil.field === col)
                    .map((fil) => fil.value)
                    .join('~~');
                let filterType = gridFilter.selectedFilters.filter(
                    (fil) => fil.field === col
                )[0].filterType;

                let temp = {
                    ColumnName: col,
                    ColumnNameValues:
                        filterType === 'CheckBox' ? filterVal : null,
                    FilterCondition: gridFilter.selectedFilters.filter(
                        (fil) => fil.field === col
                    )[0].operator,
                    FilterPredicate: gridFilter.selectedFilters.filter(
                        (fil) => fil.field === col
                    )[0].predicate,
                    FilterValue:
                        filterType === 'Menu'
                            ? gridFilter.selectedFilters.filter(
                                  (fil) => fil.field === col
                              )[0].value
                            : null,
                    GridFilterType: filterType,
                    CreationDate: new Date(),
                    IsActive: 1,
                    isGridFilter: true,
                };
                DatasetFilter.push(temp);
            });
            toBeSaved.DatasetFilter = DatasetFilter;
            datasetService
                .savefilters(toBeSaved)
                .then((res) => {
                    this.$emit('closeModal');
                    this.showSuccess = true;
                    this.filterCategory = '';
                    this.filterDescription = '';
                    this.filterName = '';
                })
                .catch((err) => {
                    this.showFailure = true;
                });
        },
        handleReset() {},
        closeModal() {
            this.$emit('closeModal');
        },
        forceReset() {},
        hideModal() {
            this.$emit('closeModal');
        },
    },
    computed: {
        ...mapState({
            gridFilters: (state) => state.gridFilters.selectedFilters,
        }),
        ...mapGetters('gridFilters', { getGridFilters: 'getGridFilters' }),
    },
};
</script>

<style></style>
